exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-author-page-tsx": () => import("./../../../src/templates/authorPage.tsx" /* webpackChunkName: "component---src-templates-author-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-demo-detail-page-tsx": () => import("./../../../src/templates/demoDetailPage.tsx" /* webpackChunkName: "component---src-templates-demo-detail-page-tsx" */),
  "component---src-templates-hub-page-tsx": () => import("./../../../src/templates/hubPage.tsx" /* webpackChunkName: "component---src-templates-hub-page-tsx" */),
  "component---src-templates-lead-gen-tsx": () => import("./../../../src/templates/leadGen.tsx" /* webpackChunkName: "component---src-templates-lead-gen-tsx" */),
  "component---src-templates-legal-page-tsx": () => import("./../../../src/templates/legalPage.tsx" /* webpackChunkName: "component---src-templates-legal-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-pricing-page-tsx": () => import("./../../../src/templates/pricingPage.tsx" /* webpackChunkName: "component---src-templates-pricing-page-tsx" */)
}

